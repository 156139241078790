import { Link } from "react-router-dom";
import Countdown from 'react-countdown';
import useWindowDimensions from "./useWindowDimensions";

function Header(){

    const { height, width } = useWindowDimensions();
    const dateCopy = width > 420 ? 'NINTH | NOVEMBER | TWENTY-FOUR' : '09.11.24'
    return (<>
        <Links />
        <div className="title-text">ALEX & JOSH</div>
        <div className="title-subtext">{dateCopy}</div>
        <div>
            <Countdown date={'2024-11-09T00:00:00'} renderer={CountRender} />
        </div>
        <div className="divider thin"></div>

        </>
    )
}

function Links (){

    return(
        <>
        <div className="links">
            <Link to='/' className='georgia'>HOME</Link>
            <Link to='/rsvp' className='georgia'>RSVP</Link>
            <Link to='/venue' className='georgia'>VENUE</Link>
            <Link to='/accommodation' className='georgia'>ACCOMMODATION</Link>
            <Link to='/faq' className='georgia'>FAQ</Link>
        </div>
        </>
    )
}

function CountRender(props){
    return <div className="title-date">{props.days} days to go!</div>
}

export default Header;
