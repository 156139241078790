import HomePics from "../components/HomePics";
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

function HomePage(){

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        var id = searchParams.get('id');
        if (id !== null) localStorage.setItem('house_id', id);
    })

    return (<div className="home-pics-outer">
            <HomePics />
            <div></div>
            <div className="two-col">
                <div style={{overflow:'hidden'}}>
                    <img src={"img/pic3_50.png"} />
                    <div className="caption"><i>Tuscany, July 2023 💍</i></div>
                </div>
                <div className="full-width-text">
                    <div className="script">Hello from Alex & Josh!</div>
                    <div className="left-paragraph">Welcome to our wedding website, where the excitement begins!</div>
                    <div className="left-paragraph">We're thrilled to invite you to join us in celebrating our love story. Tuscany, July last year, was where it all began with a sweet "Yes!" under the Italian sun.</div>
                    <div className="left-paragraph">As we embark on this journey together, we can't wait to share our special weekend with you. Explore the venue details, accommodation links and don't forget to RSVP - because your presence will make our day even more unforgettable.</div>
                    <div className="left-paragraph"><b>Please <Link to={'/rsvp'}>RSVP</Link> by April 30th!</b></div>
                    <div className="left-paragraph">We can't wait to see you there! Cheers to love, laughter, and happily ever after! 🥂</div>
                   
                </div>
            </div>
            </div>)


}

export default HomePage;