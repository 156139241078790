import './App.css';
import { Toaster } from 'react-hot-toast';
import image from './wyrsedale.png';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useSearchParams
} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import RSVPPage from './pages/RSVPPage';
import VenuePage from './pages/VenuePage';
import AcommPage from './pages/AcommPage';
import FAQPage from './pages/FAQPage';

import ConfettiExplosion from 'react-confetti-explosion';

function App() {
  return (
    <div className="App">
        <Router>
          <Header />
          <Routes>
            <Route exact path='/' element={<HomePage />} />
            <Route exact path='/rsvp' element={<RSVPPage />} />
            <Route exact path='/venue' element={<VenuePage />} />
            <Route exact path='/accommodation' element={<AcommPage />} />
            <Route exact path='/faq' element={<FAQPage />} />
          </Routes>
          <Footer />
        </Router>
        <Toaster />
    </div>
  );
}

/*
function App(){

  return(
    <div className='App'>
        <div className="divider"></div>
        <div className="title-text">ALEX & JOSH</div>
        <div className="title-subtext">09.11.24</div>
        <div className="divider thin"></div>
        <div className='script'>Save the Date!</div>
    </div>
  )

}
*/
export default App;
