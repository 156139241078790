function Footer(){

    return (<div className="footer">
        
        <div className="divider thin"></div>
        <div>Need help with anything? Email alexandragibbons8@gmail.com</div>
        <p />
        <div style={{fontSize: '10px'}}>Made with ❤️ by Josh</div>
        </div>
    )
}

export default Footer;