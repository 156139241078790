import { MapContainer, TileLayer, Marker, Popup, Icon } from 'react-leaflet'
import markerIconPng from "../../node_modules/leaflet/dist/images/marker-icon.png";
import * as L from 'leaflet';
L.Marker.prototype.setIcon(L.icon({
  iconUrl:markerIconPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -41]
}))
function Map(props){

    const defHeight = props.height||'200px';
    const defwidth = props.width||'200px';

    const markers = props.markers.map(marker => {
        return(
            <Marker position={[marker.lat, marker.long]}>
                <Popup>
                    <b>{marker.label1}</b>
                    {marker.label2.split('--').map(line=>{
                        return <><br />{line}</>
                    })}
                </Popup>
            </Marker>
        )

    })

    return(
        <MapContainer
            center={[props.lat, props.long]}
            zoom={props.zoom||13}
            scrollWheelZoom={true}
            style={{height:defHeight, width:defwidth}}
            attributionControl={false}
            >
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
            {markers}
        </MapContainer>
    )
}

export default Map;