import Map from '../components/Map';

function VenuePage(){

    return (
        <div>
            <h1>The Venue</h1>
            <div className='full-width-text'>
Our wedding will be hosted at Wyrsedale Hall, nearby the village of Scorton, near Lancaster.
<p /><div className='venue-pic'><img src={`/img/wyre1.jpg`} /></div>
<p />The celebrations will take place over two days, Friday 8th and Saturday 9th, all taking place at Wyrsedale.
<p />There is some limited on-site accommodation, which has been pre-allocated. There is an on-site café which will be open for breakfast on the day of and day after the wedding ceremony. There is also a daily swim group for open water swimming in the nearby lake, if anyone fancies it!
<p /><div className='venue-pic'><img src={`/img/wyre2.jpg`} /></div>
<p />For those arriving by car, there is ample free parking on-site, and there are also local taxi firms available.
<p />On Friday evening, we would like to invite all guests to a pre-wedding party. Please arrive at 7pm for food, drinks and our first toast!
<p />On Saturday, the ceremony will be taking place at 1pm. Josh will be opening welcome drinks at 12pm, so please feel free to join him in calming the nerves. Alex will try to arrive in time for the 1pm ceremony.
<p />The venue closes at 1am on Saturday. For those not staying at the venue, please book taxis (no five and drives!).
<p />If you'd like to see more of the venue, you can view the <a href="https://wyresdaleweddings.co.uk/" target='_blank'>website</a> and the <a href="https://www.instagram.com/wyresdalepark/" target='_blank'>Instagram</a> page.
<p /><div className='venue-pic'><img src={`/img/wyre3.jpg`} /></div>
</div>
            <div className='flex center' style={{marginTop:'40px'}}>
                <Map height={'500px'} width={'500px'}
                    lat={53.93640} long={-2.75138}
                    zoom={10}
                    markers={[
                        {lat:53.93640, long:-2.75138,
                        label1:'Wyresdale Park',
                        label2:'Snowhill Lane--Preston--PR3 1BA'}
                    ]}
                />
            </div>

        </div>
        )


}

export default VenuePage;