import { Splide, SplideSlide } from '@splidejs/react-splide';
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import useWindowDimensions from './useWindowDimensions';

function HomePics() {

    var picCount = 7;

    const { height, width } = useWindowDimensions();

    console.log('Width is '+width)

    picCount++;
    var pics = Array.from(Array(picCount).keys());
    pics.shift()
    pics = pics.map(x=> {
        return (
            <SplideSlide key={x} >
                <img className="home-img" src={`/img/pic${x}_50.png`} />
            </SplideSlide>

        )
    })

    return(
        <div className="home-pics-inner">
            <Splide 
            options={ {
                rewind: false,
                width : width,
                gap   : '0px',
                type: 'loop',
                perMove: 1,
                pagination: false,
                fixedWidth: '384px',
                autoScroll: {
                    pauseOnHover: true,
                    pauseOnFocus: false,
                    rewind: false,
                    speed: 1
                  }
                }}
                extensions={{ AutoScroll }}
                >
                {pics}
            </Splide>
        </div>
    )


}

/*
function HomePics() {

    var picCount = 7;

    picCount++;
    var pics = Array.from(Array(picCount).keys());
    pics.shift()
    pics = pics.map(x=> {
        return (
            <div className="home-pics">
                <img className="home-img" src={`/img/pic${x}_50.png`} />
            </div>
        )
    })

    return(
        <div className="home-pics-outer">
            <div className="home-pics-inner">
                {pics}
            </div>
        </div>
    )


}*/

export default HomePics;