function FAQPage(){

    return (
        <div>
                        <h1>FAQs</h1>

<div className='full-width-text'>
<p /><b>RSVP</b>
<p />Please ensure you submit your RSVP by April 30th. If you have any problems using the form on this website, please email alexandragibbons8@gmail.com and she will contact her support team, joshgrime@gmail.com.
<p /><b>Travel</b>
<p />If arriving by car/taxi, the post code is PR3 1BA.
<p />For those arriving by train, we recommended travelling to Lancaster and taxiing from there.
<p /><b>Friday Evening</b>
<p />We'd love to see you on both days, but we appreciate that this may not be possible for everyone. Please let us know if you're coming on Friday in your RSVP - if you can't make it then we will look forward to welcoming you on Saturday.
<p /><b>Food & Drink</b>
<p />Food and drink will be provided on Friday evening, Saturday lunch and Saturday evening.
<p />Breakfast for Saturday and Sunday morning for those on-site can be brought with you as there are cooking facilities, or you can use the on-site café.
<p /><b>Dietary Requirements</b>
<p />Please capture any dietary requirements in your RSVP.
<p /><b>Dress Code</b>
<p />Please coming wearing your best garms!
<p />The venue will be warm and dry, but it could be chilly outside, so bring a jacket and brolly. It's November in the North West of England! 😬
<p /><b>Wedding Theme</b>
<p />There is no specific theme and all colours are welcome (except white of course).
<p /><b>Footwear</b>
<p />Make sure you've brought your dancing shoes.
<p /><b>Children</b>
<p />Children are welcome to attend the entire weekend.
<p /><b>Photography and Social Media</b>
<p />Please take as many photos as possible on the day to share with us! There will be a professional photographer snapping pics, but we want to see all of your candid pics in the wild too! Feel free to share on social media as you please.
<p /><b>Gifts</b>
<p />We are incredibly grateful for your love and support in our celebrations. Gifts are not expected, but if you would like to, we kindly request contributions towards our honeymoon.
<p /><b>Sunday</b>
<p />We will be heading off on our mini-moon Sunday morning, so we will say goodbye to you all on Saturday night! 👋

</div>
        </div>
    )


}

export default FAQPage;