import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../components/Spinner'
import ConfettiExplosion from 'react-confetti-explosion';
import toast from 'react-hot-toast';

function RSVPPage(){
    
    const baseUrl = 'https://server.alexandjosh.co.uk/'
    const [searchParams, setSearchParams] = useSearchParams();
    const [code, setCode] = useState(null);
    const [pin, setPin] = useState(null);
    const [loading, setLoading] = useState(true);
    const [household, setHousehold] = useState([]);
    const [formComplete, setFormComplete] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        var id = searchParams.get('id');
        if (id !== null) localStorage.setItem('house_id', id);
        setCode(localStorage.getItem('house_id'));
        setLoading(false);
    }, []);

    function _setCode(id){
        localStorage.setItem('house_id', id);
        setCode(localStorage.getItem('house_id'));
    }

    function _setPin(pin) {
        setPin(pin);
        setLoading(true);

        axios({
            method: 'post',
            url: baseUrl + 'login',
            headers: {}, 
            data: {
              id: code,
              pin: pin
            }
          }).then(x=>{
            if (x.status === 200) {
                console.log('200 !')
                setHousehold(x.data);
                setLoading(false);
            }
          }).catch(e=>{
            var status = e.response.status;
            setPin(null);
            setLoading(false);
            if (status === 401) {
                console.log('401 error');
                toast.error("Hmm, that didn\'t work. 🤔\nPlease check your ID and pin.", {position: 'bottom-center', duration: 10000});
            }
            else if (status === 500) {
                toast.error("Error with the server, Josh needs to fix something 😅", {position: 'bottom-center', duration: 10000})
            }
          });

    }

    function updateDetail(guestid, attribute, value) {

        var new_household = [...household];
        for (let guest of new_household) {
            if (guestid === guest.id) {
                guest[attribute] = value;
            }
        }

        setHousehold(new_household)

        console.log('Updating guest '+guestid+'\'s '+attribute+' with '+value);
        console.log('New household is:');
        console.log(household);
    }

    function saveDetails() {
        setLoading(true);

        axios({
            method: 'post',
            url: baseUrl + 'update-guests',
            headers: {}, 
            data: {
              pin: pin,
              household: household
            }
          }).catch(e=>{
            console.log(e);
            setCode(null);
            setPin(null);
            setLoading(false);
            toast.error("Error with the server, Josh needs to fix something 😅", {position: 'bottom-center', duration: 10000})
          }).then(x=>{
            if (x.status === 200) {
                console.log('200 !');
                setFormComplete(true);
                setLoading(false);
                makeConfetti();
                toast.success("RSVP Saved", {position: 'bottom-center', duration: 5000});
            }
          });
    }

    function makeConfetti(){
        setShowConfetti(true);
        setTimeout(function(){
            setShowConfetti(false);
        }, 3000);
    }

    var content = 
        code === null ? <div>Please scan your QR code, found with your invitation.</div> : 
        pin === null ? <EnterCodeForm type={'pin'} callback={_setPin}  /> : 
        formComplete === true ? <FormComplete callback={()=>{setFormComplete(false)}} /> :
        <RSVPForm code={code} household={household} updateCallback={updateDetail} saveCallback={saveDetails} />

    var confetti = showConfetti ? <div style={{position: 'absolute', left: '50%'}}><ConfettiExplosion particleCount={250} duration={3000} force={0.8} width={1600} colors={['#d21e67','#FFFFFF', '#e6558f','#ed87b0', '#e8acc4']} /></div> : null;
    return (<div>
            {confetti}
            {loading?<Spinner />:content}
            </div>);

}

function RSVPForm(props) {

    const rsvpForm = props.household !== null ? props.household.map(guest => {
        return <GuestForm guest={guest} key={guest.id} callback={props.updateCallback} />
    }) : null;

    const submitButton = props.household !== null ? <button className='josh-btn' style={{marginTop: '40px'}} onClick={props.saveCallback}>Save</button> : null;

    return(
        <div>
            
            Please use the below form to send your RSVP!

            {rsvpForm}

            {submitButton}

        </div>
    )
}

function GuestForm(props) {

    var guest = props.guest;
    const [rsvp, setRsvp] = useState(guest.rsvp);
    const [friday, setFriday] = useState(guest.friday);
    const [diet, setDiet] = useState(guest.diet);
    const [song, setSong] = useState(guest.song);
    const [msg, setMsg] = useState(guest.message);

    var switch_cn = rsvp ? 'on':'off';
    var yesno1_cn = rsvp ? 'grey' : '';
    var yesno2_cn = rsvp ? 'pink' : 'grey';

    var friday_switch_cn = friday ? 'on':'off';
    var friday_yesno1_cn = friday ? 'grey' : '';
    var friday_yesno2_cn = friday ? 'pink' : 'grey';

    function toggleRsvp() {
        let c_rsvp = !rsvp;
        setRsvp(c_rsvp);
        props.callback(guest.id, 'rsvp', c_rsvp);
    }

    function toggleFriday() {
        let c_rsvp = !friday;
        setFriday(c_rsvp);
        props.callback(guest.id, 'friday', c_rsvp);
    }

    function updateDiet(e) {
        let choice = e.target.value;
        setDiet(choice);
        props.callback(guest.id, 'diet', choice);
    }

    function updateSong(e) {
        let song = e.target.value;
        setSong(song);
        props.callback(guest.id, 'song', song);
    }

    function updateMsg(e) {
        let msg = e.target.value;
        setMsg(msg);
        props.callback(guest.id, 'message', msg);
    }
    
    //console.log(props.guest)

    var MoreForm = !rsvp ? null :
    <div className='guest-form-part2'>
        <div className='flex spread padding40'>
            <div className='form-header'>Friday evening RSVP</div>
            <div className='flex'>
                <div className={'guest-form-rsvp-yesno '+friday_yesno1_cn}>No</div>
                    <div className={'guest-form-rsvp-holder '+friday_switch_cn} onClick={toggleFriday}>
                       <div className={'rsvp-switch '+friday_switch_cn} ></div>
                    </div>
                    <div className={'guest-form-rsvp-yesno '+friday_yesno2_cn}>Yes!</div>
                </div>
            </div>
        <div className='flex spread padding20 reactive'>
            <div className='form-header'>Please select your menu</div>

            <div className='flex center reactive smolwidth'>
                <div className='flex spread padding20'>
                <input type="radio" id={'meat'+guest.id} name={'menu'+guest.id} value="meat" className='menu-input' defaultChecked={diet==='meat'||diet===''} onChange={updateDiet} />
                <label htmlFor={'meat'+guest.id} className='menu-label'>Meat</label>
                </div>
                <div className='flex spread padding20'>
                <input type="radio" id={'veggie'+guest.id} name={'menu'+guest.id} value="vegetarian" className='menu-input' defaultChecked={diet==='vegetarian'} onChange={updateDiet} />
                <label htmlFor={'veggie'+guest.id} className='menu-label'>Vegetarian</label>
                </div>
                <div className='flex spread padding20'>
                <input type="radio" id={'vegan'+guest.id} name={'menu'+guest.id} value="vegan" className='menu-input' defaultChecked={diet==='vegan'} onChange={updateDiet} />
                <label htmlFor={'vegan'+guest.id} className='menu-label'>Vegan</label>
                </div>
                <div className='flex spread padding20'>
                <input type="radio" id={'child'+guest.id} name={'menu'+guest.id} value="child" className='menu-input' defaultChecked={diet==='child'} onChange={updateDiet} />
                <label htmlFor={'child'+guest.id} className='menu-label'>Child (Under 12)</label>
                </div>
            </div>

        </div>
        <div className='flex spread padding20 reactive'>
            <div className='form-header'>If you could have one song played on the night...</div>
            <div className='form-input-hold'>
                <input onChange={updateSong} defaultValue={guest.song} className='form-input'></input>
            </div>
        </div>
        <div className='flex spread padding20 reactive'>
            <div className='form-header'>Leave us a message</div>
            <div className='form-input-hold'>
                <input onChange={updateMsg} defaultValue={guest.message} className='form-input'></input>
            </div>
        </div>
    </div>

    return(
        <div className='guest-form'>
            <div className='flex spread padding40'>
                <div className='guest-form-name'>{guest.name} {guest.surname}</div>
                <div className='guest-form-name-checkbox flex'>
                <div className={'guest-form-rsvp-yesno '}>RSVP</div>
                    <div className={'guest-form-rsvp-yesno '+yesno1_cn}>No</div>
                    <div className={'guest-form-rsvp-holder '+switch_cn} onClick={toggleRsvp}>
                       <div className={'rsvp-switch '+switch_cn} ></div>
                    </div>
                    <div className={'guest-form-rsvp-yesno '+yesno2_cn}>Yes!</div>
                </div>
            </div>

            {MoreForm}

        </div>
    )
}

function EnterCodeForm(props) {

    const [id, setId] = useState('');

    function updateId(e) {
        setId(e.target.value);
    }

    function submitClick(){
        props.callback(id);
        setId('');
    }

    function enterHandler(event) {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            submitClick();
        }
    }

    let msg = props.type === 'code' ? 'Enter your ID code' : 'Type in your pin number found underneath the QR code on your invite.';
    let btn_text = props.type === 'code' ? 'Next' : 'Submit';

    return(<>
        <div>{msg}</div>
        <div><input onChange={updateId} value={id} onKeyDown={enterHandler} className='pin-input' /></div>
        <div><button className='josh-btn' onClick={submitClick}>{btn_text}</button></div>
        </>
    )

}

function FormComplete(props){
    return(
        <div>
        <div style={{margin: '5%'}}>Thank you for sending your RSVP! You can return to this page at any time to update your details.</div>
        <div>
        <button className='josh-btn' onClick={props.callback}>Edit Details</button>
        </div>
        </div>
    )
}

export default RSVPPage;