import Map from '../components/Map';

function AcommPage(){

    return (
        <div>
            <h1>Where to stay</h1>

            <div className='full-width-text' style={{marginBottom: '40px'}}>
There is limited on-site accommodation, which has been pre-allocated.
<p />There are, however, many hotels and Airbnbs in the local area.
<p />We have included some links below to places which have been recommended to us within a 15-minute drive. If in doubt, please feel free to reach out to one of us to help.

        <div className='accom-hold'>
            <h2>The Priory</h2>
            <div><i>On the edge of Bowland Forest, The Priory bed and breakfast offers a cosy accommodation in the centre of Scorton. Free WiFi is available, and the M6 motorway is 15 minute drive away.</i></div>
            <p />
            <div><a href="https://www.theprioryscorton.co.uk/" target='_blank'>Website</a></div>
            <div><a href="https://www.booking.com/hotel/gb/the-priory-inn.en-gb.html" target='_blank'>Booking.com</a></div>
        </div>

        <div className='accom-hold'>
            <h2>The Hamilton</h2>
            <div><i>Situated on the outskirts of Scorton village, The Hamilton offers multiple luxury apartment lets.</i></div>
            <p />
            <div><a href="https://www.booking.com/hotel/gb/the-hamilton-luxury-holiday-lets-holly-barn.en-gb.htm" target='_blank'>Holly Barn</a></div>
            <div><a href="https://www.booking.com/hotel/gb/hamilton-the-cruck-luxury-let.en-gb.html" target='_blank'>The Cruck</a></div>
            <div><a href="https://www.booking.com/hotel/gb/hamilton-the-alexander-suite-luxury-holiday-lets.en-gb.html" target='_blank'>The Alexander Suite</a></div>
            <div><a href="https://www.booking.com/hotel/gb/the-hamilton-the-studio-luxury-holiday-lets.en-gb.html" target='_blank'>The Studio</a></div>
            <div><a href="https://www.booking.com/hotel/gb/hamilton-lord-william.en-gb.html" target='_blank'>Lord William</a></div>
            <div><a href="https://www.booking.com/hotel/gb/the-hamilton-luxury-holiday-lets-the-coach-house-with-hot-tub.en-gb.html" target='_blank'>The Coach House</a></div>
            <div><a href="https://www.booking.com/hotel/gb/the-hamilton-luxury-let-the-duke.en-gb.html" target='_blank'>The Duke</a></div>
        </div>

        <div className='accom-hold'>
            <h2>The Royal Oak, Garstang</h2>
            <div><i>Located in the next village, Garstang, 3 miles from Scorton, this pub/hotel has great reviews.</i></div>
            <p />
            <div><a href="https://www.royaloakgarstang.co.uk/" target='_blank'>Website</a></div>
            <div><a href="https://www.booking.com/hotel/gb/royal-oak-garstang.en-gb.html" target='_blank'>Booking.com</a></div>
        </div>

        <div className='accom-hold'>
            <h2>Garstang Country Hotel</h2>
            <div><i>Situated in the picturesque market town of Garstang, this family-owned hotel is beautifully set on the banks of the River Wyre and boasts open views of the Lancashire countryside.</i></div>
            <p />
            <div><a href="https://www.garstanghotelandgolf.com/" target='_blank'>Website</a></div>
            <div><a href="https://www.booking.com/hotel/gb/best-western-garstang-country-golf-club.en-gb.html" target='_blank'>Booking.com</a></div>
        </div>

        <div className='accom-hold'>
            <h2>Travelodge</h2>
            <div><i>There is a Travelodge nearby, which is a 15 minute drive to the venue.</i></div>
            <p />
            <div><a href="https://www.travelodge.co.uk/hotels/142/Lancaster-M6-hotel" target='_blank'>Website</a></div>
        </div>

</div>


            <div className='flex center'>
                <Map height={'500px'} width={'500px'}
                    lat={53.9321} long={-2.7598}
                    zoom={12}
                    markers={[
                        {lat:53.93296, long:-2.75925,
                        label1:'The Priory',
                        label2:'The Square--Scorton--PR3 1AU'},

                        {lat:53.96132, long:-2.76236,
                        label1:'Travelodge',
                        label2:'Moto Service Area J32/33--White Carr Lane--Lancaster--LA2 9DU'},

                        {lat:53.89992, long:-2.77454,
                        label1:'Royal Oak Hotel',
                        label2:'Market Place--Garstang--Preston--PR3 1ZA'},

                        {lat:53.93780, long:-2.77492,
                        label1:'The Hamilton',
                        label2:'Preston Lancaster Road--Preston--PR3 1AB'},

                        {lat:53.88716, long:-2.77079,
                        label1:'Garstang Country Hotel',
                        label2:'Bowgreave Drive--Bowgreave--Preston--PR3 1YE'},

                    ]}
                />
            </div>

        </div>
        )
}

export default AcommPage;